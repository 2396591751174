import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllPositions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/f/clientpositions', )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchInstructer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getinstructordetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCoursesbyInstructers(ctx,  { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursesbyinstructer/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInstructerStatus(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructerstatus', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteInstructer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/deleteinstructer', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInstructorphoto(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructorphoto', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createposition(ctx, userData) {
      console.log(userData);

      return new Promise((resolve, reject) => {
        axios
          .post('/admin/f/createposition', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
